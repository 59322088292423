import styled from 'styled-components';

import colours from '_theming/colours';
import breakpoints from '_util/responsiveDesign/breakpoints';

// Extract responsive design variables.
const { mobile, tablet } = breakpoints;

/**
 * Container for a section which has one column.
 */
const ContainerOneColumn = styled.section`
  display: flex;
  flex-flow: column nowrap;

  padding: 0 25px;

  color: ${colours.linkWater};
`;

/**
 * Component for a section which has two columns.
 */
const ContainerTwoColumn = styled.section`
  display: flex;

  @media screen and (max-device-width: ${mobile.max}px) {
    flex-flow: column nowrap;
    flex-direction: column;

    & > * {
      flex-basis: 100%;
    }
  }

  @media screen and (min-device-width: ${tablet.min}px) {
    flex-flow: row nowrap;
    justify-content: space-between;
    flex-direction: ${(props) => props.flexDirection || 'row'};

    & > * {
      flex-basis: 49%;
    }
  }

  padding: 0 25px;

  color: ${colours.linkWater};
`;

/**
 * Container for the graphic.
 */
const ContainerGraphic = styled.div`
  svg {
    width: 100%;
    height: auto;

    max-width: 500px;
  }

  text-align: center;
`;

/**
 * Container for the text.
 */
const ContainerText = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;

  font-size: 1.125rem;
`;

/**
 * Section H1 header.
 */
const H1 = styled.h1`
  @media screen and (max-device-width: ${mobile.max}px) {
    font-size: 1.5rem;
  }

  @media screen and (min-device-width: ${tablet.min}px) {
    font-size: 2.5rem;
  }

  font-family: 'Big Caslon Medium', serif;
  text-align: ${(props) => props.textAlign || 'left'};

  margin-top: 0;
`;

/**
 * Container for the buttons.
 */
const ContainerButtons = styled.div`
  @media screen and (max-device-width: ${mobile.max}px) {
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
    align-items: center;

    & > a {
      width: 100%;
    }

    & > a:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  @media screen and (min-device-width: ${tablet.min}px) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    & > a:not(:last-child) {
      margin-right: 32px;
    }
  }

  margin-top: 32px;
  width: 100%;
`;

export {
  ContainerOneColumn,
  ContainerTwoColumn,
  ContainerGraphic,
  ContainerText,
  H1,
  ContainerButtons,
};
