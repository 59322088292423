import styled from 'styled-components';

/**
 * Container for the graphic.
 */
const ContainerGraphic = styled.div`
  svg {
    max-width: 400px;
    width: auto;
    height: auto;
  }

  text-align: center;
`;

export { ContainerGraphic };
