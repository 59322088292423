import React from 'react';
import styled from 'styled-components';

import { ContainerOneColumn, H2 } from '_templates/Sections';
import BoyLookingUpIllustration from '_images/svg/illustrations/BoyLookingUp.svg';
import useResponsive from '_hooks/useResponsive';
import useIsClient from '_hooks/useIsClient';

/**
 * Table component.
 */
const Table = styled.div`
  display: table;
  border-spacing: 10px;
`;

/**
 * Table row component.
 */
const Row = styled.div`
  display: table-row;
`;

/**
 * Table cell component.
 */
const Cell = styled.div`
  display: table-cell;

  text-align: ${(props) => props.textAlign || 'left'};
`;

// List of fees paired for a 2-column layout.
const feesPaired = [
  ['E-Statement Fees', 'Rush Fees'],
  ['Wireless Setup Fees', 'Annual Fees'],
  ['Terminal Setup Fees', 'Service Fees'],
  ['Minimum Discount Fees', 'Seasonal Fees'],
  ['PCI Administration Fees', 'One-Time Fees'],
  ['PCI Protect Program Fees', 'Statement Fees'],
  ['PCI Non-Compliance Fees', 'Settlement Fees'],
  ['System Maintenance Fees', 'Application Fees'],
  ['PIN Pad Key Injection Fees', 'Monthly Bill Fees'],
  ['High Risk Assessment Fees', 'Authorization Fees'],
  ['Interchange Differential Fees', 'Administration Fees'],
  ['Monthly Support Services Fees', 'Network Access Fees'],
  ['Onsite Wireless Consultation Fees', 'Online Reporting Fee'],
  ['Payment Network Compliance Fees', '$25+ Chargeback Fees'],
];

// List of fees flattened to one large array sorted by string length in
// ascending order.
const feesFlattened = feesPaired.flat().sort((a, b) => a.length - b.length);

/**
 * Creates a key for React.
 *
 * @param {string} str String used to generate the key.
 * @returns {string} Key.
 */
const createKey = (str) => str.toLowerCase().replace(/\s/g, '');

/**
 * Container for the desktop/tablet table.
 */
const ContainerDesktopTable = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-end;
`;

/**
 * Container for the mobile table.
 */
const ContainerMobileTable = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

/**
 * Container for the SVG.
 */
const ContainerSVG = styled.span`
  svg {
    height: 100px;
    width: auto;
  }

  margin-right: 40px;
`;

/**
 * Table of fees for the Desktop and Tablet layouts.
 *
 * @returns {React.Component} Table of fees.
 */
const DesktopTabletFees = () => (
  <Table>
    {feesPaired.map(([item1, item2]) => (
      <Row key={createKey(`${item1}${item2}`)}>
        <Cell textAlign="right">{item1}</Cell>
        <Cell textAlign="left">{item2}</Cell>
      </Row>
    ))}
  </Table>
);

/**
 * Table of fees for the Mobile layout.
 *
 * @returns {React.Component} Table of fees.
 */
const MobileFees = () => (
  <Table>
    {feesFlattened.map((item) => (
      <Row key={createKey(item)}>
        <Cell textAlign="center">{item}</Cell>
      </Row>
    ))}
  </Table>
);

/**
 * The "You Only Pay The Fees We Pay" section.
 *
 * @returns {React.Component} Section.
 */
const YouOnlyPayTheFeesWePay = () => {
  const { isMobile } = useResponsive();
  const isClient = useIsClient();

  return (
    <ContainerOneColumn>
      <H2>
        With Pricematepay, you only pay the fees we pay, but other merchant service providers might
        charge:
      </H2>

      {isClient ? (
        <React.Fragment>
          {isMobile ? (
            <ContainerMobileTable>
              <MobileFees />
            </ContainerMobileTable>
          ) : (
            <ContainerDesktopTable>
              <ContainerSVG>
                <BoyLookingUpIllustration />
              </ContainerSVG>

              <DesktopTabletFees />
            </ContainerDesktopTable>
          )}
        </React.Fragment>
      ) : null}
    </ContainerOneColumn>
  );
};

export default YouOnlyPayTheFeesWePay;
