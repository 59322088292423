import React from 'react';

import { ContainerOneColumn, H2 } from '_templates/Sections';

/**
 * The "Price Protection Guarantee" section.
 *
 * @returns {React.Component} Section.
 */
const PriceProtectionGuarantee = () => (
  <ContainerOneColumn>
    <H2>Pricematepay’s Price Protection Guarantee</H2>

    <p>
      Every year, processing companies increase the fees and rates of the businesses they support.
      In many cases, the increased pricing metrics are confusing, and it can be hard to understand
      where they are coming from.
    </p>

    <p>
      At Pricematepay, we give you the confidence that your business has an agent waiting for you
      who will differentiate the pricing increase from the processor and card brands to help your
      business maintain the lowest possible pricing available.
    </p>
  </ContainerOneColumn>
);

export default PriceProtectionGuarantee;
