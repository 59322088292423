import React from 'react';
import styled from 'styled-components';

import { ContainerOneColumn, H2 } from '_templates/Sections';
import breakpoints from '_util/responsiveDesign/breakpoints';
import colours from '_theming/colours';

// Extract responsive design variables.
const { mobile, tablet, desktop } = breakpoints;

/**
 * Card component.
 */
const Card = styled.div`
  background-color: ${(props) => props.bgColor || colours.white};
  color: ${(props) => props.textColor || colours.daintree};

  border-radius: 16px;
  padding: 10px;

  text-align: center;

  @media screen and (max-device-width: ${mobile.max}px) {
    width: 100%;
  }

  @media screen and (min-device-width: ${tablet.min}px) {
    padding: 26px;
  }
`;

/**
 * Card title.
 */
const CardTitle = styled.h3`
  margin-top: 0;
  font-family: 'Big Caslon Medium', serif;
`;

/**
 * Card list.
 */
const List = styled.ul`
  margin: 0;
  padding: 0;

  list-style-type: none;
`;

/**
 * Card list item.
 */
const Item = styled.li`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

/**
 * Card title component (Total Processing Fees).
 */
const CardTitleTotal = styled.div`
  font-family: 'Big Caslon Medium', serif;
  font-size: 2rem;
`;

/**
 * Interchange Fees card.
 *
 * @returns {React.Component} Card.
 */
const CardInterchangeFees = () => (
  <Card bgColor={colours.saffron}>
    <CardTitle>Interchange Fees</CardTitle>
    <List>
      <Item>go to the bank which issues the card</Item>
      <Item>These fees are non-negotiable</Item>
    </List>
  </Card>
);

/**
 * Assessment Fees card.
 *
 * @returns {React.Component} Card.
 */
const CardAssessmentFees = () => (
  <Card bgColor={colours.saffron}>
    <CardTitle>Assessment Fees</CardTitle>
    <List>
      <Item>go to the card brands (e.g. Visa, Mastercard, American Express)</Item>
      <Item>These fees are non-negotiable</Item>
    </List>
  </Card>
);

/**
 * Markup Fees card.
 *
 * @returns {React.Component} Card.
 */
const CardMarkupFees = () => (
  <Card bgColor={colours.tropicalBlue}>
    <CardTitle>Markup Fees</CardTitle>
    <List>
      <Item>go to the credit card processors</Item>
      <Item>These fees are negotiable</Item>
    </List>
  </Card>
);

/**
 * Total Processing Fees card.
 *
 * @returns {React.Component} Card.
 */
const CardTotalProcessingFees = () => (
  <Card bgColor={colours.fountainBlue}>
    <CardTitleTotal>Total Processing Fees</CardTitleTotal>
  </Card>
);

/**
 * Container for the cards used in summation.
 */
const ContainerSummation = styled.div`
  @media screen and (max-device-width: ${tablet.max}px) {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }

  @media screen and (min-device-width: ${desktop.min}px) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  text-align: center;
`;

/**
 * Container for the cards used in the solution.
 */
const ContainerSolution = styled.div`
  text-align: center;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

/**
 * Container for any math-related symbols.
 */
const ArithmeticSymbol = styled.span`
  font-size: 2rem;
  margin: 10px;
`;

/**
 * Description.
 */
const Description = styled.p`
  text-align: center;
`;

/**
 * The "The Components Of Fees" section.
 *
 * @returns {React.Component} Section.
 */
const TheComponentsOfFees = () => (
  <ContainerOneColumn>
    <H2>The components of fees</H2>

    <Description>
      The fees you pay can be broken down into three parts: Interchange fees, assessment fees and
      processor markup fees.
    </Description>

    <ContainerSummation>
      <CardInterchangeFees />

      <ArithmeticSymbol>&#43;</ArithmeticSymbol>

      <CardAssessmentFees />

      <ArithmeticSymbol>&#43;</ArithmeticSymbol>

      <CardMarkupFees />
    </ContainerSummation>

    <ContainerSolution>
      <ArithmeticSymbol>&#61;</ArithmeticSymbol>

      <CardTotalProcessingFees />
    </ContainerSolution>
  </ContainerOneColumn>
);

export default TheComponentsOfFees;
