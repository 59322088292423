import React from 'react';

import {
  ContainerTwoColumn,
  ContainerGraphic,
  ContainerText,
  ContainerButtons,
  H1,
} from '_templates/LandingSection';
import colours from '_theming/colours';
import ExternalLinkButton from '_components/ExternalLinkButton';
import InternalLinkButton from '_components/InternalLinkButton';
import MeetingIllustration from '_images/svg/illustrations/Meeting.svg';

/**
 * The "Let Us Help You Process Your Options" section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const LetUsHelpYouProcessYourOptions = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphic>
      <MeetingIllustration />
    </ContainerGraphic>

    <ContainerText>
      <H1>Let us help you process your options</H1>

      <p>
        Too many businesses are paying too much for their merchant services with fixed or blended
        fees on their transactions, which allow payment processors to mark up the fees you pay.
      </p>

      <p>
        With Pricematepay’s fair and transparent Interchange Plus pricing models, you can rest
        assured that you’re getting the best possible value for years to come. And with no
        additional hidden fees, that means you bring home more of your hard-earned money with every
        transaction.
      </p>

      <ContainerButtons>
        <ExternalLinkButton
          href="https://gateway.pricematepay.com/signup"
          bgcolor={colours.redDamask}
          target="_blank"
        >
          Get Started Now
        </ExternalLinkButton>

        <InternalLinkButton to="/contact/" bgcolor={colours.linkWater}>
          Get a Quote
        </InternalLinkButton>
      </ContainerButtons>
    </ContainerText>
  </ContainerTwoColumn>
);

export default LetUsHelpYouProcessYourOptions;
