import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import colours from '_theming/colours';

/**
 * Custom Gatsby Link component.
 */
const CustomGatsbyLink = styled(Link)`
  display: block;

  font-family: inherit;
  color: ${colours.daintree};
  background-color: ${(props) => props.bgcolor || colours.white};
  text-decoration: none;
  text-align: center;

  border-radius: 4px;
  padding: 12px 14px;

  box-shadow: 0 2px 4px 0 rgba(0, 43, 61, 0.2);
`;

/**
 * Internal Link Button.
 *
 * @param {object} props Component props.
 * @param {string} props.to Link destination.
 * @param {string} props.className CSS class name.
 * @param {string} props.bgcolor Background colour.
 * @param {React.Component} props.children Child nodes to be rendered.
 * @returns {React.Component} Link Button.
 */
const InternalLinkButton = ({ className, to, bgcolor, children }) => (
  <CustomGatsbyLink to={to} className={className} bgcolor={bgcolor}>
    {children}
  </CustomGatsbyLink>
);

InternalLinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  bgcolor: PropTypes.string,
  children: PropTypes.node.isRequired,
};

InternalLinkButton.defaultProps = {
  className: null,
  bgcolor: colours.daintree,
};

export default InternalLinkButton;
