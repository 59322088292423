import React from 'react';
import styled from 'styled-components';

import { ContainerOneColumn, H2 } from '_templates/Sections';
import breakpoints from '_util/responsiveDesign/breakpoints';
import useIsClient from '_hooks/useIsClient';
import useResponsive from '_hooks/useResponsive';
import YourFeesAtAGlanceDesktopIllustration from '_images/svg/illustrations/YourFeesAtAGlanceDesktop.svg';
import YourFeesAtAGlanceMobileIllustration from '_images/svg/illustrations/YourFeesAtAGlanceMobile.svg';

// Extract responsive design variables.
const { mobile, tablet, desktop } = breakpoints;

/**
 * Container SVG.
 */
const ContainerSVG = styled.div`
  text-align: center;

  @media screen and (min-device-width: ${mobile.max}px) and (max-device-width: ${tablet.min}px) {
    svg {
      width: 100%;
      height: auto;
    }
  }

  @media screen and (min-device-width: ${desktop.min}px) {
    svg {
      max-width: 1000px;
      width: 100%;
      height: auto;
    }
  }
`;

/**
 * The "Your Fees at a Glance" section.
 *
 * @returns {React.Component} Section.
 */
const YourFeesAtAGlance = () => {
  const { isMobile } = useResponsive();
  const isClient = useIsClient();

  return (
    <ContainerOneColumn>
      <H2>Your fees at a glance:</H2>

      {isClient ? (
        <div>
          <ContainerSVG>
            {isMobile ? (
              <YourFeesAtAGlanceMobileIllustration />
            ) : (
              <YourFeesAtAGlanceDesktopIllustration />
            )}
          </ContainerSVG>
        </div>
      ) : null}
    </ContainerOneColumn>
  );
};

export default YourFeesAtAGlance;
