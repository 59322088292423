import React from 'react';

import { ContainerTwoColumn, ContainerButtons, H2 } from '_templates/Sections';
import colours from '_theming/colours';
import ExternalLinkButton from '_components/ExternalLinkButton';
import InternalLinkButton from '_components/InternalLinkButton';
import SalesTeamIllustration from '_images/svg/illustrations/SalesTeam.svg';

import { ContainerGraphic } from './common/Section';

/**
 * The "Ready To Start Getting Paid" section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const ReadyToStartGettingPaid = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphic>
      <SalesTeamIllustration />
    </ContainerGraphic>

    <div>
      <H2>Ready to start getting paid? </H2>

      <p>
        We help businesses compare and activate the best payment processing options for in-store and
        online sales.
      </p>
      <p>Lower fees. Smarter tech. And a bigger bottom line.</p>

      <ContainerButtons>
        <ExternalLinkButton
          href="https://gateway.pricematepay.com/signup"
          bgcolor={colours.redDamask}
          target="_blank"
        >
          Get Started Now
        </ExternalLinkButton>

        <InternalLinkButton to="/contact/" bgcolor={colours.linkWater}>
          Get a Quote
        </InternalLinkButton>
      </ContainerButtons>
    </div>
  </ContainerTwoColumn>
);

export default ReadyToStartGettingPaid;
